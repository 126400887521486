<template>
  <div class="left-nav fl">
    <div title="服务云" class="logo-image" />
    <template v-if="hasNoLeftIcon">
      <el-link
        v-if="leftIcons.includes('Workbench')"
        :underline="false"
        :href="
          currentApp === 'web'
            ? null
            : `/v20/${
              BASE_URL.WEB
            }/account/user/back-to-workbench.svc?returnUrl=${getActiveUrl()}?current=${currentId}`
        "
        class="vice-text"
        :class="{ active: currentRoute === 'Workbench' }"
        @click="openWorkbench"
      >
        <svg-icon-comp
          class="vice-icon"
          :icon-class="currentRoute === 'Workbench' ? 'home_active' : 'home'"
        />
        <span class="panel-label">工作台</span>
      </el-link>
      <div
        v-if="leftIcons.includes('Handle')"
        class="panel"
      >
        <span class="panel-label_tool" @click.stop="showMenu('link', $event)">
          <svg-icon-comp :icon-class="current !== '全部产品' && currentApp !== 'web' ? 'menu-list-active' : 'menu-list-dark'" class="menu-icon" @click.native.stop="showMenu" />
          <span
            class="panel-content"
            :class="{ active: current !== '全部产品' && currentApp !== 'web' }"
            @click.stop="showMenu('link', $event)"
          >{{ current }}</span>
        </span>
        <div v-if="menuShow" class="panel-menu-mask">
          <div
            class="panel-menu clearfix"
            @click.stop
          >
            <div class="panel-menu-left fl" :style="{ height: `${currentHeight}px` }">
              <svg-icon-comp :icon-class="'nav-label'" class="panel-title" />
              <div class="panel-menu-tips">
                服务云是一个面向明源产品全生命周期的服务平台，快来获取适合你的服务工具，开始高效、愉悦的工作吧
              </div>
            </div>
            <div ref="rightPanel" class="panel-menu-right fl">
              <dl
                v-for="({ title, menus, icon }, index) in menuList"
                :key="`${title}${index}`"
                class="menu-list "
              >
                <dt class="title">
                  <svg-icon-comp class="menu-list-icon" :icon-class="icon" />{{
                    title
                  }}
                </dt>
                <dd class="menu-content">
                  <template
                    v-for="{ label, url, AppCode, description, MenuCode, hasPermission, noPermissionReason } in menus"
                    :key="AppCode"
                  >
                    <!--  hasPermission -1表示'敬请期待'， 0表示正常跳转  1表示没有租户权限   2表示没有用户权限               -->
                    <el-link
                      slot="reference"
                      :underline="false"
                      class="menu"
                      :href="url && !hasPermission ? `/v20/mysm/frontend/app/go-to-app.svc?AppCode=${AppCode}&MenuCode=${MenuCode || '001'}&RedirectUrl=${url}?current=${AppCode}`: null"
                      :class="{ active: label === current, 'has-time': hasPermission }"
                      @mouseenter.stop
                      @click.stop="openPage(url, AppCode, noPermissionReason, hasPermission)"
                    >
                      <div class="menu-name" :title="label">
                        <span class="menu-name_label">{{ label }}</span>
                        <el-tooltip
                          v-if="hasPermission"
                          effect="dark"
                          popper-class="header-tooltips"
                          :content="noPermissionReason"
                          :append-to-body="true"
                          :disabled="!hasPermission || !noPermissionReason"
                          placement="top"
                        >
                          <span class="menu-no-url" :class="hasPermission > 0 ? 'no-permission' : ''">{{ hasPermission > 0 ? '无权限' : '敬请期待' }}</span>
                        </el-tooltip>
                      </div>
                      <div class="menu-description" :title="description">
                        {{ description || '--' }}
                      </div>
                    </el-link>
                  </template>
                </dd>
              </dl>
            </div>
            <svg-icon-comp
              :icon-class="currentCloseIcon"
              class="menu-icon_close"
              @click.stop="hideMenu"
              @mouseenter="currentCloseIcon = 'close_active'"
              @mouseleave="currentCloseIcon = 'close'"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getActiveHttp } from '../../../utils'
import { BASE_URL } from '../../../utils/constants'
import SvgIconComp from '../../../components/SvgIcon/index'
// import logo from '../../../assets/icon/logo_new.svg'
export default {
  name: 'Left',
  components: { SvgIconComp },
  props: {
    leftIcons: {
      type: Array,
      default() {
        return ['Workbench', 'Handle']
      }
    },
    menuList: { type: Array, required: true },
    current: { type: String, required: true },
    currentApp: { type: String, default: '' },
    currentId: { type: String, default: '' }, // 当前工具AppCode
    hasNoLeftIcon: { type: Boolean, default: true } // 是否不需要图标
  },
  data() {
    return {
      // logo,
      BASE_URL,
      menuShow: false,
      currentCloseIcon: 'close',
      currentTimeout: null,
      currentHeight: 484
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name
    }
  },
  watch: {
    menuShow: {
      handler(menuShow) {
        if (!menuShow) {
          clearTimeout(this.currentTimeout)
          this.currentTimeout = null
        } else {
          this.$nextTick(() => {
            const height = this.$refs.rightPanel.offsetHeight
            if (height > this.currentHeight) this.currentHeight = height
          })
        }
      }
    }
  },
  mounted() {
    window.addEventListener('click', this.hideMenu)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.hideMenu)
  },
  methods: {
    getActiveUrl() {
      return getActiveHttp(
        'https://mysm.fdcyun.com',
        'https://mysm-test.fdcyun.com',
        `http://localhost:7777`,
        false,
        'https://auth-qa.fdcyun.com'
      )
    },
    getCurrentUrl() {
      const { menuList, currentId } = this
      let routes = []
      menuList.forEach(val => {
        routes = [...routes, ...val.menus]
      })
      const currentRoute = routes.filter(routesItem => routesItem.appCode === currentId)[0]
      return currentRoute ? `/v20/mysm/frontend/app/go-to-app.svc?AppCode=${currentRoute.AppCode}&MenuCode=${currentRoute.MenuCode || '001'}&RedirectUrl=${currentRoute.url}?current=${currentRoute.AppCode}` : null
    },
    openWorkbench() {
      if (this.$route.name !== 'Workbench') {
        this.$router.push({ name: 'Workbench' })
      }
    },
    showMenu() {
      this.menuShow = !this.menuShow
    },
    hideMenu() {
      this.menuShow = false
    },
    openPage(url) {
      if (!url) {
        // this.$message.info(`工具建设中，敬请期待～`)
      }
    }
  }
}
</script>
<style lang="scss">
.nav-popper {
  padding: 8px 12px !important;
  font-size: 12px !important;
}
</style>
<style lang="scss" scoped>
@import '../../../styles/variables';
.left-nav {
  width: 100%;
  display: flex;
  position: relative;
  .logo-image {
    width: 146px;
    height: 30px;
    margin-top: 10px;
    background: url('https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/workbench/logo_new2.svg')
      no-repeat center center / 100% 100%;
  }
  .vice-text {
    margin-left: 20px;
    color: $titleFontColor;
    font-size: 14px;
    cursor: pointer;
    font-weight: normal;
    height: $navBarHeight;
    line-height: $navBarHeight;
    margin-right: 20px;
    .vice-icon {
      margin: 0 6px;
      font-size: 16px;
      vertical-align: middle;
    }
    &::before {
      content: '';
      height: 30%;
      border-left: $border;
      margin-right: 12px;
    }
    &:hover,
    &.active {
      color: $themecolor;
    }
  }
  .panel-label_tool {
    cursor: pointer;
  }
  .panel-label {
    font-size: 14px;
    vertical-align: middle;
    line-height: 22px;
  }
  .panel {
    overflow: hidden;
    vertical-align: middle;
    height: $navBarHeight;
    line-height: $navBarHeight;
    .menu-icon {
      font-size: 16px;
      margin-right: 6px;
      vertical-align: middle;
      cursor: pointer;
    }
    .panel-content {
      font-weight: normal;
      color: $titleFontColor;
      line-height: 22px;
      vertical-align: middle;
      cursor: pointer;
      font-size: 14px;
      &.active,
      &:hover {
        color: $themecolor;
      }
    }
  }
  .panel-menu-left {
    width: 318px;
    background: rgb(244, 246, 245)
      url('https://myom-release.oss-cn-hangzhou.aliyuncs.com/mysm/resource/workbench/nav-bg.svg')
      no-repeat center center / cover;
    min-height: 484px;
    height: 100%;
    padding: 56px 0 0 66px;
    .panel-title {
      display: inline-block;
      width: 124px;
      height: 22px;
      margin-bottom: 14px;
    }
    .panel-menu-tips {
      width: 232px;
      height: 66px;
      opacity: 1;
      color: rgba(102, 102, 102, 1);
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 26px;
    }
    .panel-menu-handel {
      width: 221px;
      height: 58px;
      border-radius: 6px;
      opacity: 1;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(243, 245, 248, 1) 100%
      );
      box-shadow: 0 4px 8px 0 rgba(90, 114, 175, 0.3);
      margin-bottom: 10px;
      position: relative;
      padding-top: 6px;
      .panel-menu-label {
        color: #222222;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        line-height: 22px;
        margin-bottom: 4px;
        padding-left: 72px;
      }
      .panel-menu-desc {
        color: rgba(83, 93, 116, 1);
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        line-height: 20px;
        padding-left: 72px;
      }
      .panel-menu-icon {
        position: absolute;
        top: 50%;
        left: 20px;
        font-size: 32px;
        transform: translateY(-50%);
      }
      &.ticket {
        background: linear-gradient(
          0deg,
          rgba(80, 122, 254, 1) 0%,
          rgba(50, 99, 255, 1) 100%
        );
        .panel-menu-label {
          color: rgba(255, 255, 255, 1);
        }
        .panel-menu-desc {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      &:hover {
        box-shadow: 0 4px 24px 0 rgba(90, 114, 175, 0.3);
      }
    }
  }
  .panel-menu-mask  {
    position: absolute;
    top:  $navBarHeight + 1px;
    left: -$panelLeftPadding;
    z-index: 3006;
    line-height: 0px;
    width: calc( 100% + #{$panelLeftPadding * 2} );
    height: calc( 100vh - #{$navBarHeight - 1px} );
    background: rgba(0,0,0,.3);
  }
  .panel-menu {
    width: 100%;
    min-height: 484px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: $backgroundColor;
    // box-shadow: 0 3px 8px 0 rgba(0,0,0,0.1);
    .panel-menu-right {
      width: calc(100% - 318px);
      padding: 32px 40px 20px 40px;
      box-sizing: border-box;
    }
    .menu-icon_close {
      position: absolute;
      top: $padding;
      right: $padding;
      cursor: pointer;
      font-size: 28px;
    }
    .title {
      color: rgba(34, 34, 34, 1);
      font-size: 16px;
      margin-bottom: $margin;
      font-weight: 400;
      padding-left: 10px;
      .menu-list-icon {
        margin-right: 12px;
      }
    }
    .menu-list {
      margin-bottom: $margin - 2px;
      &:first-of-type {
        .title {
          margin-top: 0;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .menu {
      font-weight: normal;
      width: calc((100% - 8px) / 5);
      justify-content: left;
      margin-bottom: 3px;
      padding: 8px 12px;
      margin-right: 2px;
      height: 68px;
      overflow: hidden;
      &:nth-of-type(5n) {
        margin-right: 0;
      }
      ::v-deep .el-link--inner {
        display: grid;
      }
      .menu-name {
        width: 100%;
        font-size: 14px;
        color: $titleFontColor;
        margin-bottom: 8px;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 22px;
        .menu-name_label {
          vertical-align: middle;
        }
        .menu-no-url {
          font-size: 10px;
          font-weight: 400;
          text-align: left;
          margin-left: 6px;
          color: #798fc5;
          width: 48px;
          height: 17px;
          padding: 2px 4px;
          border-radius: 3px;
          cursor: not-allowed;
          background: rgba(244, 248, 254, 1);
          &.has-time {
            color: $themecolor;
          }
          &.no-permission {
            color: #FF9902 !important;
            background-color: #FFF5E2 !important;
          }
        }
      }
      .menu-description {
        font-size: 13px;
        font-weight: 400;
        color: $bodyFontColor;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 22px;
      }
      &:hover,
      &.active {
        border-radius: 3px;
        background: rgba(245, 245, 245, 1);
        .menu-name {
          color: $themecolor;
        }
      }
      &.has-time {
        cursor: not-allowed;
      }
    }
  }
}
</style>
